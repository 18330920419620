import axios from "axios";

let api_url = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: api_url,
  responseType: "json",
  withCredentials: true,
  headers: {
  },
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (!error.response) {
      alert(error);
      return Promise.reject(error);
    }

    if (error.response.status === 401 || error.response.status === 403) {
      if (window.location.pathname !== "/login")
        window.location.replace("/login");
      return Promise.reject(error);
    }

    if (error.response.status === 400) {
      return Promise.reject(error);
    }
    if (error.response.status === 409) {
      return Promise.reject(error);
    }

    if (error.response.status === 500) {
      console.log(error.response);
      alert(error.response.statusText);
    }

    //alert(error.response);
    console.log(error.response);
    return Promise.reject(error);
  }
);

export default instance;
