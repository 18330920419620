import * as React from "react";
import Dashboard from "../components/Dashboard";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Trans, withTranslation } from "react-i18next";
import axios from "../components/Axios";

import "./index.css";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawer_open: true,
      loading: true,
      items: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    axios.get("/numbers/list").then((response) => {
      this.setState({ loading: false });
      console.log(response.data);
      this.setState({ items: response.data });
    });
  }

  representationUrl(o) {
    let params = Object.entries(o)
      .filter(([key, value]) => value !== false && value !== undefined)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return process.env.REACT_APP_API_URL + "letter.php?" + params;
  }

  deleteItem(id){
    console.log(id);
    axios
      .post("numbers/delete", {
        _id: id,
      })
      .then((response) => {
        this.componentDidMount();
      });
  }

  render() {
    return (
      <Dashboard title="Dashboard">
        {this.state.items.map((i, k) => (
          <Paper key={"number-" + k} className="representationPaper">
            <Typography component="div" variant="h5">
              {i.name} ({i.number})
            </Typography>
            <div className="letterDiv">
              {i.representation.map((c, ck) => (
                <img
                  key={"letter-" + ck}
                  src={this.representationUrl(c)}
                  style={{ height: "100px" }}
                />
              ))}
            </div>
            
            <div className="representationIcons">
              <IconButton
                aria-label="delete"
                onClick={this.deleteItem.bind(this, i._id)}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </div>
          </Paper>
        ))}
        <Paper className="representationPaper" hidden={this.state.items.length>0}>Aucun affichages</Paper>
      </Dashboard>
    );
  }
}

export default withTranslation()(Index);
