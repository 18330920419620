import React from "react";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import StyledToggleButtonGroup from "../components/toggleButtons";
import Menu from "@mui/material/Menu";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Dashboard from "../components/Dashboard";

//import { Editor } from "@tinymce/tinymce-react";
import axios from "../components/Axios";

import "./index.css";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      editorRef: React.createRef(),
      input: localStorage.getItem("name") || "",
      selected: null,
      menuAnchor: null,
      representation: [],
      phone: localStorage.getItem("number") || "",
      snackOpen: false,
    };
  }

  componentDidMount() {
    this.inputChange({ target: { value: this.state.input } });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  inputChange(e) {
    let input = e.target.value.trim();
    this.setState({ input: input });
    localStorage.setItem("name", input);

    for (let i = 0; i < input.length; i++) {
      let l = input.charAt(i);

      if (i in this.state.representation) {
        this.state.representation[i]["letter"] = l;
      } else {
        let style = "arrow";
        if (l == l.toLowerCase()) {
          style = "filled";
        }
        this.state.representation.push({
          letter: l,
          bold: false,
          style: style,
          color: "black",
        });
      }
    }

    if (this.state.representation.length > input.length) {
      this.state.representation.splice(input.length, this.state.representation.length - input.length);
    }
    console.log(this.state.representation);
    this.setState({ representation: this.state.representation });
  }

  sendForm(e) {
    e.preventDefault();
    let formData = new FormData(e.target);
    let data = Object.fromEntries(formData);

    if (this.state.editorRef.current) {
      data["content"] = this.state.editorRef.current.getContent();
    }

    console.log(data);

    this.setState({ loading: true });

    axios.post("api/send_mail", data).then((response) => {
      this.setState({ loading: false });
      console.log(response.data);
      if ("error" in response.data) {
      } else {
        //success
      }
    });

    return false;
  }

  selectLetter(k, e) {
    this.setState({ selected: k, menuAnchor: e.currentTarget });
  }
  closeMenu() {
    this.setState({ selected: false, menuAnchor: null });
  }

  toggleBold() {
    let i = this.state.selected;
    this.state.representation[i]["bold"] = !this.state.representation[i]["bold"];
    this.setState({ representation: this.state.representation });
  }
  setStyle(style) {
    let i = this.state.selected;
    this.state.representation[i]["style"] = style;
    this.setState({ representation: this.state.representation });
  }

  representationUrl(o) {
    let params = Object.entries(o)
      .filter(([key, value]) => value !== false && value !== undefined)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join("&");
    return process.env.REACT_APP_API_URL + "letter.php?" + params;
  }

  saveNumber() {
    console.log(this.state.phone);
    this.setState({ loading: true });
    axios.post("numbers/save", { number: this.state.phone, name: this.state.input, representation: this.state.representation }).then((response) => {
      this.setState({ loading: false });
      console.log(response.data);
      if ("error" in response.data) {
        if ("code" in response.data && response.data.code == 11000) {
          alert("Le numéro est déjà enregistré dans Mes Affichages. Pour le modifier, supprimez le dans Mes Affichages, puis recréez le.");
        } else {
          alert(response.data.error);
        }
      } else {
        //success
        this.setState({ snackOpen: true });
      }
    });
  }

  render() {
    return (
      <Dashboard title="Chiffrier">
        <Container maxWidth="md" hidden>
          <Paper sx={{ m: 4, p: 2 }}>
            <form onSubmit={this.sendForm.bind(this)} name="contact">
              <TextField size="small" margin="dense" fullWidth required name="email" label="Email" defaultValue="wei@autonomal.com" />
              <TextField size="small" margin="dense" fullWidth required name="title" label="Subject" defaultValue="Sujet" />
              <div className="tinyLabel">Message</div>
              {/*<Editor onInit={(evt, editor) => (this.state.editorRef.current = editor)} apiKey="m8wopg3064gfz6hcxp3fv0hu9332x5xper7hm9mx2b0u2wso"></Editor>*/}
              <br />
              <Button variant="contained" disabled={this.loading} type="submit">
                Send
              </Button>
            </form>
          </Paper>
        </Container>
        <Container maxWidth={false} style={{ padding: 0 }}>
          <Paper sx={{ m: 4, p: 2 }}>
            <TextField size="small" margin="dense" fullWidth required name="chiffrier" label="Nom à representer" value={this.state.input} onChange={this.inputChange.bind(this)} />
            {/*<img src={process.env.REACT_APP_API_URL + "api/representation?t=" + encodeURIComponent(this.state.input)} />*/}
            <Menu className="controlsMenu" anchorEl={this.state.menuAnchor} open={this.state.menuAnchor} onClose={this.closeMenu.bind(this)}>
              <div className="controlsDiv">
                <div className="controls">
                  <StyledToggleButtonGroup size="small" value={""} exclusive onChange={null}>
                    {["arrow", "empty", "filled", "tall", "inverted"].map((i, k) => (
                      <ToggleButton key={"toggle-" + i} onClick={this.setStyle.bind(this, i)}>
                        <img
                          className="toggleStyleImg"
                          src={
                            process.env.REACT_APP_API_URL +
                            "letter.php?letter=" +
                            (this.state.selected in this.state.representation ? encodeURIComponent(this.state.representation[this.state.selected].letter) : "m") +
                            "&style=" +
                            i
                          }
                        />
                      </ToggleButton>
                    ))}
                  </StyledToggleButtonGroup>
                  <Divider flexItem orientation="vertical" />
                  <StyledToggleButtonGroup size="small">
                    <ToggleButton value="bold" aria-label="bold" onClick={this.toggleBold.bind(this)}>
                      <FormatBoldIcon />
                    </ToggleButton>
                    <ToggleButton value="color" aria-label="color" disabled>
                      <FormatColorFillIcon />
                      <ArrowDropDownIcon />
                    </ToggleButton>
                  </StyledToggleButtonGroup>
                </div>
              </div>
            </Menu>
            <div className="representationDiv" style={{minHeight: '200px'}}>
              {this.state.representation.map((i, k) => (
                <img key={"letter-" + k} src={this.representationUrl(i)} className={this.state.selected === k ? "selected" : ""} onClick={this.selectLetter.bind(this, k)} />
              ))}
            </div>
            <TextField
              InputLabelProps={{ shrink: true }}
              size="small"
              margin="dense"
              fullWidth
              required
              name="number"
              label="Numéro de téléphone"
              value={this.state.phone}
              onChange={(e) => {
                this.setState({ phone: e.target.value });
                localStorage.setItem("number", e.target.value);
              }}
            />
            <Button variant="contained" onClick={this.saveNumber.bind(this)} disabled={this.state.loading}>
              Sauvergarder
            </Button>
          </Paper>
        </Container>
        <Snackbar
          open={this.state.snackOpen}
          onClose={() => {
            this.setState({ snackOpen: false });
          }}
          autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => {
              this.setState({ snackOpen: false });
            }}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Affichage sauvegardé
          </Alert>
        </Snackbar>
      </Dashboard>
    );
  }
}

export default Index;
