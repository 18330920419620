import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Trans, withTranslation } from "react-i18next";
import axios from "../components/Axios";

import "./register.css";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: "",
      email: "",
      password: "",
    };
  }

  componentDidMount() {}

  handleSubmit(e) {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    let obj = Object.fromEntries(data);
    console.log(obj);

    this.setState({ loading: true, errorMessage: "" });
    axios
      .post("/user/login", obj)
      .then(
        function (response) {
          console.log(response.data);
          //this.setState({items: response.data});
          if ("error_code" in response.data) {
            this.setState({
              loading: false,
              errorMessage: response.data.message,
            });
          } else {
            this.setState({ loading: false });
            localStorage.setItem("id", response.data.id);
            //localStorage.setItem("user_id", response.data.user_info.user_id);
            //localStorage.setItem(
            //  "user",
            //  JSON.stringify(response.data.user_info)
            //);
            //axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
            window.location.replace("/");
            //this.props.history.push("/");
          }
        }.bind(this)
      )
      .catch(
        function (error) {
          if (error.response.status === 400 || error.response.status === 409) {
            this.setState({ errorMessage: error.response.data.message });
          } else {
            this.setState({ errorMessage: "Unknown Error" });
          }
          console.log(error.response);
          this.setState({ loading: false });
        }.bind(this)
      );
  }

  render() {
    return (
      <div className="bgDiv">
        <Container maxWidth="xs" className="registerDiv">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <Box
              component="form"
              onSubmit={this.handleSubmit.bind(this)}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={'test@mottel.ca'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    value={'123456'}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Login
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/register" variant="body2">
                    Don't have an account? Register
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(Index);
